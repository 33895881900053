import { Box } from "@mui/material";

const Fallback = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        WebkitAnimation: "fadeinout 1s linear forwards",
        animation: "fadeinout 1s linear forwards",
      }}
    >
      {/* <Logo /> */}

      <Box
      component="img"
      src="/assets/logo_black.png"
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    />
    </Box>
  );
};

export default Fallback;
