import { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Fallback from "./containers/Fallback";
import PrivateRoute from "./hocs/PrivateRoute";
import { CustomSnackbar } from "./hocs/CustomeSnackbar";

const Login = lazy(() => import("./containers/Login"));
const Dashboard = lazy(() => import("./containers/Dashboard"));

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CustomSnackbar>
        <Suspense fallback={<Fallback />}>
          <Router>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route
                path="dashboard/*"
                element={<PrivateRoute component={Dashboard} />}
              />
              <Route path="*" element={<Navigate to="dashboard" replace />} />
            </Routes>
          </Router>
        </Suspense>
      </CustomSnackbar>
    </QueryClientProvider>
  );
}

export default App;
